// cmp
import GroupComponent from "../cmp/devices/group-component";
import D0006 from "../cmp/devices/D-0006";
import D0008 from "../cmp/devices/D-0008";
import D0100 from "../cmp/devices/D-0100";
import D0101 from "../cmp/devices/D-0101";
import D0102 from "../cmp/devices/D-0102";
import D0300 from "../cmp/devices/D-0300";
import D0502 from "../cmp/devices/D-0502";
import D0702 from "../cmp/devices/D-0702";
import DC38A from "../cmp/devices/D-C38A";
import DC38E from "../cmp/devices/D-C38E";
import DF1F0 from "../cmp/devices/D-F1F0";
import DFC00 from "../cmp/devices/D-FC00";
import DFD00 from "../cmp/devices/D-FD00";
import DFD05 from "../cmp/devices/D-FD05";
import DFF69 from "../cmp/devices/D-FF69";
import DFF71 from "../cmp/devices/D-FF71";
import DFF80 from "../cmp/devices/D-FF80";
import DFF82 from "../cmp/devices/D-FF82";
import DFF83 from "../cmp/devices/D-FF83";
import DFF84 from "../cmp/devices/D-FF84";
import DFF85 from "../cmp/devices/D-FF85";
import DFF92 from "../cmp/devices/D-FF92";
import DFF96 from "../cmp/devices/D-FF96";
import DFF97 from "../cmp/devices/D-FF97";
import DFF99 from "../cmp/devices/D-FF99";
import DFF9A from "../cmp/devices/D-FF9A";
import DFF9B from "../cmp/devices/D-FF9B";
import DFF9C from "../cmp/devices/D-FF9C";
import DFF9D from "../cmp/devices/D-FF9D";
import DFF9E from "../cmp/devices/D-FF9E";
import DFFAC from "../cmp/devices/D-FFAC";
import DFFAD from "../cmp/devices/D-FFAD";
import DFFAE from "../cmp/devices/D-FFAE";
import DXXXX from "../cmp/devices/D-XXXX";
import UnitDisplay from "../cmp/devices/UnitDisplay";
// services
import Gateway from "./gateway";
import Constants from "./constants";
import ClusterConstants from "./cluster-constants";
import i18n from "./i18n";
import { getNumberFormatter } from "./l10n";
import { getColorPrimariesFromCluster, convertColorXYToColorRGB } from "./color";
import { roundToPrecision, convertToF, hex2Decimal, START_SWITCH_OPERATION_STATE_ENABLED } from "./utils";
// types
import type { DeviceObj, EpDevice } from "../types/device";
import type {
	IncapsCluster0006, IncapsCluster0008, IncapsCluster000C, IncapsCluster0100, IncapsCluster0101,
	IncapsCluster0102, IncapsCluster0300, IncapsCluster0400, IncapsCluster0402, IncapsCluster0403,
	IncapsCluster0405, IncapsCluster0500, IncapsCluster0702, IncapsClusterFC00, IncapsClusterFD01,
	IncapsClusterFD02, IncapsClusterFD03, IncapsClusterFD04, IncapsClusterFD05, IncapsClusterFF69,
	IncapsClusterFF71, IncapsClusterFF80, IncapsClusterFF81, IncapsClusterFF82, IncapsClusterFF84,
	IncapsClusterFF85, IncapsClusterFF92, IncapsClusterFF94, IncapsClusterFF95, IncapsClusterFF96,
	IncapsClusterFF9A, IncapsClusterFF9E, IncapsClusterFF9F, IncapsClusterFFAC,
	OutcapsClusterC38A, OutcapsClusterC38E,
} from "../types/cluster";
import type { GetUnitFormat, Options } from "../types/device-type";
import type { ColorPrimaries, ColorRGB } from "../types/misc";

const getUnitFormat: GetUnitFormat = (that, cluster, options) => {
	const value = that.getValue(cluster);
	const digits = that.getDigits();
	const unit = that.getUnit();

	if (options.simple) {
		const numberFormatter = getNumberFormatter(digits);
		return `${numberFormatter(value)} ${unit}`;
	}
	return (
		<UnitDisplay
			values={[value]}
			digits={digits}
			unit={unit}
			sameFontSize={Boolean(options.sameFontSize)}
		/>
	);
};

/**
 * A constant file to represent the different types of devices based on clusterid and a mapping b/w
 * clusterId to view handler for the device.
 * Format:
 *  clusterId: cluster id
 *  cap: represents in which caps the clusterId should be searched.(incaps/outcaps)
 *  attributeId: default attribute id
 *  cmp: Represents the component which will be used to parse the device feature.
 *  getTitle: Function @returns the title used for cluster
 *  getIcon: Function @returns the icon to the unit
 *  getValue(@param {cluster}): Function @return value
 *  getUnit: Function @return the unit used for cluster
 *  formatValue: Function @return the formatted value for cluster.
 *  getDeviceIconsFromDevice(@param {device}): Function to return the icon from raw device object and roc dictionary
 *
 * @constant
 */
const DeviceType = {
	D0003: {
		clusterId: "0003",
		cap: Constants.Caps.Incaps,
		readOnly: true,
	},
	D0004: {
		clusterId: "0004",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D0004.Attributes.Groups,
		cmp: GroupComponent,
	},
	D0006: {
		clusterId: "0006",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D0006.Attributes.OnOff,
		cmp: D0006,
		getValue: function(cluster: IncapsCluster0006) {
			const value = cluster[this.attributeId]; // boolean
			if (typeof value !== "boolean") {
				return undefined;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: boolean) {
			return value;
		},
		formatValue: function(cluster: IncapsCluster0006) {
			return this.getValue(cluster);
		},
		getDeviceIconsFromDevice: function(device: DeviceObj | EpDevice) {
			return device.getDeviceIcons(this.cap, this.clusterId, this.getIconStatusFromCluster);
		},
		getIconStatusFromCluster: function(cluster: IncapsCluster0006) {
			const value = cluster[ClusterConstants.D0006.Attributes.OnOff];
			return Boolean(value);
		},
	},
	D0008: {
		clusterId: "0008",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D0008.Attributes.OnOff,
		cmp: D0008,
		getValue: function(cluster: IncapsCluster0008) {
			const value = cluster[this.attributeId]; // 8-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.Percent;
		},
		getDigits: function() {
			return 0;
		},
		decodeValue: function(value: number) {
			return Math.round(value * 100 / 255);
		},
		formatValue: function(cluster: IncapsCluster0008, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	D000C: {
		clusterId: "000C",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D000C.Attributes.PresentValue,
		cmp: DXXXX,
		getTitle: function() {
			return i18n.t("clusters.D000C.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_dew_point.svg";
		},
		getValue: function(cluster: IncapsCluster000C) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.Degree + Gateway.selectedGatewayTemperature;
		},
		getDigits: function() {
			return 1;
		},
		decodeValue: function(value: number) {
			return (Gateway.selectedGatewayTemperature === Constants.TempUnit.Fahrenheit)
				? convertToF(value / 100)
				: roundToPrecision(value / 100);
		},
		formatValue: function(cluster: IncapsCluster000C, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	D0019: {
		clusterId: "0019",
		cap: Constants.Caps.Outcaps,
		readOnly: true,
	},
	D0100: {
		clusterId: "0100",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D0100.Attributes.Mode,
		cmp: D0100,
		getValue: function(cluster: IncapsCluster0100) {
			const value = cluster[this.attributeId]; // 8-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: number) {
			return value;
		},
		formatValue: function(cluster: IncapsCluster0100) {
			return this.getValue(cluster);
		},
	},
	D0101: {
		clusterId: "0101",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D0101.Attributes.LockStatus,
		cmp: D0101,
		getValue: function(cluster: IncapsCluster0101) {
			const value = cluster[this.attributeId]; // string (16-bit integer)
			if (typeof value !== "string") {
				return undefined;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: string) {
			return value === "01"; // "01" = "locked", "02" = "unlocked"
		},
		formatValue: function(cluster: IncapsCluster0101) {
			return this.getValue(cluster);
		},
		getDeviceIconsFromDevice: function(device: DeviceObj | EpDevice) {
			return device.getDeviceIcons(this.cap, this.clusterId, this.getIconStatusFromCluster);
		},
		getIconStatusFromCluster: function(cluster: IncapsCluster0101) {
			const value = cluster[ClusterConstants.D0101.Attributes.LockStatus];
			return value === "01";
		},
	},
	D0102: {
		clusterId: "0102",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D0102.Attributes.CurrentPositionLiftPercentage,
		cmp: D0102,
		getTitle: function() {
			return i18n.t("clusters.D0102.status");
		},
		getValue: function(cluster: IncapsCluster0102) {
			const value = cluster[this.attributeId]; // 8-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.Percent;
		},
		getDigits: function() {
			return 0;
		},
		decodeValue: function(value: number) {
			return value;
		},
		formatValue: function(cluster: IncapsCluster0102, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
		getDeviceIconsFromDevice: function(device: DeviceObj | EpDevice) {
			return device.getDeviceIcons(this.cap, this.clusterId, this.getIconStatusFromCluster);
		},
		getIconStatusFromCluster: function(cluster: IncapsCluster0102) {
			const value = cluster[ClusterConstants.D0102.Attributes.CurrentPositionLiftPercentage];
			return typeof value === "number" && value < 100;
		},
	},
	D0300: {
		clusterId: "0300",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D0300.Attributes.Groups,
		cmp: D0300,
		getValue: function(cluster: IncapsCluster0300) {
			const currentX = cluster[ClusterConstants.D0300.Attributes.CurrentX]; // 16-bit integer
			const currentY = cluster[ClusterConstants.D0300.Attributes.CurrentY]; // 16-bit integer
			if (typeof currentX !== "number" || typeof currentY !== "number") {
				return {
					r: 0,
					g: 0,
					b: 0,
				} as const satisfies ColorRGB;
			}
			const colorPrimaries = getColorPrimariesFromCluster(cluster);
			return this.decodeValue(currentX, currentY, colorPrimaries);
		},
		decodeValue: function(currentX: number, currentY: number, colorPrimaries: ColorPrimaries) {
			return convertColorXYToColorRGB(currentX, currentY, colorPrimaries);
		},
		formatValue: function(cluster: IncapsCluster0300) {
			return this.getValue(cluster);
		},
	},
	D0400: {
		clusterId: "0400",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D0400.Attributes.Luminance,
		cmp: DXXXX,
		getTitle: function() {
			return i18n.t("clusters.D0400.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_luminance.svg";
		},
		getValue: function(cluster: IncapsCluster0400) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.Lux;
		},
		getDigits: function() {
			return 0;
		},
		decodeValue: function(value: number) {
			return Math.round(Math.pow(10, value / 10000) - 1);
		},
		formatValue: function(cluster: IncapsCluster0400, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	D0402: {
		clusterId: "0402",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D0402.Attributes.Temperature,
		cmp: DXXXX,
		getTitle: function() {
			return i18n.t("clusters.D0402.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_temperature.svg";
		},
		getValue: function(cluster: IncapsCluster0402) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.Degree + Gateway.selectedGatewayTemperature;
		},
		getDigits: function() {
			return 1;
		},
		decodeValue: function(value: number) {
			return (Gateway.selectedGatewayTemperature === Constants.TempUnit.Fahrenheit)
				? convertToF(value / 100)
				: roundToPrecision(value / 100);
		},
		formatValue: function(cluster: IncapsCluster0402, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	D0403: {
		clusterId: "0403",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D0403.Attributes.MeasureValuePressure,
		cmp: DXXXX,
		getTitle: function() {
			return i18n.t("clusters.D0403.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_air_pressure.svg";
		},
		getValue: function(cluster: IncapsCluster0403) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.HectoPascal;
		},
		getDigits: function() {
			return 0;
		},
		decodeValue: function(value: number) {
			return Math.round(value);
		},
		formatValue: function(cluster: IncapsCluster0403, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	D0405: {
		clusterId: "0405",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D0405.Attributes.Humidity,
		cmp: DXXXX,
		getTitle: function(stringOverrideKey: string | undefined = undefined) {
			return i18n.t("clusters.D0405.title", { context: stringOverrideKey });
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_room_humidity.svg";
		},
		getValue: function(cluster: IncapsCluster0405) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.Percent;
		},
		getDigits: function() {
			return 0;
		},
		decodeValue: function(value: number) {
			return Math.round(value / 100);
		},
		formatValue: function(cluster: IncapsCluster0405, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	D0500: {
		clusterId: "0500",
		cap: Constants.Caps.Incaps,
		readOnly: true,
		attributeId: ClusterConstants.D0500.Attributes.Alarm1,
		getValue: function(cluster: IncapsCluster0500) {
			const value = cluster[this.attributeId]; // boolean
			if (typeof value !== "boolean") {
				return undefined;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: boolean) {
			return value;
		},
		formatValue: function(cluster: IncapsCluster0500) {
			return this.getValue(cluster);
		},
		getDeviceIconsFromDevice: function(device: DeviceObj | EpDevice) {
			return device.getDeviceIcons(this.cap, this.clusterId, this.getIconStatusFromCluster);
		},
		getIconStatusFromCluster: function(cluster: IncapsCluster0500) {
			// https://roc-connect.atlassian.net/wiki/spaces/ROCW/pages/383090692/0500+-+Alarm#Zone-Type-Table
			const CUSTOM_REMOTE_ZONE_TYPE = "8000";
			const value = cluster[ClusterConstants.D0500.Attributes.Alarm1];
			if (cluster[ClusterConstants.D0500.Attributes.ZoneType] === CUSTOM_REMOTE_ZONE_TYPE || cluster.zone_type === CUSTOM_REMOTE_ZONE_TYPE) { // TODO: remove "zone_type" fallback in future
				const value2 = cluster[ClusterConstants.D0500.Attributes.Alarm2];
				const value3 = cluster[ClusterConstants.D0500.Attributes.ExtendedStatus];
				return Boolean(value) || Boolean(value2) || value3 === "01";
			} else {
				return Boolean(value);
			}
		},
	},
	D0502: {
		clusterId: "0502",
		cap: Constants.Caps.Incaps,
		cmp: D0502,
	},
	D0702: {
		clusterId: "0702",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.D0702.Attributes.InstantaneousPowerConsumption,
		cmp: D0702,
		getTitle: function() {
			return i18n.t("clusters.D0702.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_power_consumption.svg";
		},
		getValue: function(cluster: IncapsCluster0702) {
			const value = cluster[ClusterConstants.D0702.Attributes.NetPowerConsumption] ?? cluster[this.attributeId]; // 32-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			const _multiplier = cluster[ClusterConstants.D0702.Attributes.Multiplier];
			const _divisor = cluster[ClusterConstants.D0702.Attributes.Divisor];
			const multiplier = (typeof _multiplier === "number") ? _multiplier : 1; // 32-bit integer
			const divisor = (typeof _divisor === "number") ? _divisor : 1; // 32-bit integer
			return this.decodeValue(value, multiplier, divisor);
		},
		getUnit: function() {
			return Constants.Units.Watt;
		},
		getDigits: function() {
			return 1;
		},
		decodeValue: function(value: number, multiplier: number, divisor: number) {
			return roundToPrecision(value * 1000 * multiplier / divisor);
		},
		formatValue: function(cluster: IncapsCluster0702, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
		entries: {
			[ClusterConstants.D0702.Attributes.OverallPowerConsumption]: {
				id: ClusterConstants.D0702.Attributes.OverallPowerConsumption,
				getTitle: function() {
					return i18n.t("clusters.D0702.overall");
				},
				getIcon: function() {
					return "smart-widget-state-icons/si_power_consumption_new.svg";
				},
				getValue: function(cluster: IncapsCluster0702) {
					const value = cluster[this.id]; // 32-bit integer
					if (typeof value !== "number") {
						return NaN;
					}
					return this.decodeValue(value);
				},
				getUnit: function() {
					return Constants.Units.KiloWattHour;
				},
				getDigits: function() {
					return 2;
				},
				decodeValue: function(value: number) {
					return roundToPrecision(value / 100, 0.01);
				},
				formatValue: function(cluster: IncapsCluster0702, options: Options = {}) {
					return getUnitFormat(this, cluster, options);
				},
			},
			[ClusterConstants.D0702.Attributes.OverallPowerProduction]: {
				id: ClusterConstants.D0702.Attributes.OverallPowerProduction,
				getTitle: function() {
					return i18n.t("clusters.D0702.totalPowerFeedIn");
				},
				getIcon: function() {
					return "smart-widget-state-icons/si_power_production.svg";
				},
				getValue: function(cluster: IncapsCluster0702) {
					const value = cluster[this.id]; // 32-bit integer
					if (typeof value !== "number") {
						return NaN;
					}
					return this.decodeValue(value);
				},
				getUnit: function() {
					return Constants.Units.KiloWattHour;
				},
				getDigits: function() {
					return 2;
				},
				decodeValue: function(value: number) {
					return roundToPrecision(value / 100, 0.01);
				},
				formatValue: function(cluster: IncapsCluster0702, options: Options = {}) {
					return getUnitFormat(this, cluster, options);
				},
			},
			[ClusterConstants.D0702.Attributes.InstantaneousPowerConsumption]: {
				id: ClusterConstants.D0702.Attributes.InstantaneousPowerConsumption,
				cap: Constants.Caps.Incaps,
				getTitle: function() {
					return i18n.t("clusters.D0702.title");
				},
				getIcon: function() {
					return "smart-widget-state-icons/si_power.svg";
				},
				getValue: function(cluster: IncapsCluster0702) {
					const value = cluster[this.id]; // 32-bit integer
					if (typeof value !== "number") {
						return NaN;
					}
					const _multiplier = cluster[ClusterConstants.D0702.Attributes.Multiplier];
					const _divisor = cluster[ClusterConstants.D0702.Attributes.Divisor];
					const multiplier = (typeof _multiplier === "number") ? _multiplier : 1; // 32-bit integer
					const divisor = (typeof _divisor === "number") ? _divisor : 1; // 32-bit integer
					return this.decodeValue(value, multiplier, divisor);
				},
				getUnit: function() {
					return Constants.Units.Watt;
				},
				getDigits: function() {
					return 1;
				},
				decodeValue: function(value: number, multiplier: number, divisor: number) {
					return roundToPrecision(value * 1000 * multiplier / divisor);
				},
				formatValue: function(cluster: IncapsCluster0702, options: Options = {}) {
					return getUnitFormat(this, cluster, options);
				},
			},
			[ClusterConstants.D0702.Attributes.FeedIn]: {
				id: ClusterConstants.D0702.Attributes.FeedIn,
				cap: Constants.Caps.Incaps,
				getTitle: function() {
					return i18n.t("clusters.D0702.powerFeedIn");
				},
				getIcon: function() {
					return "smart-widget-state-icons/si_power.svg";
				},
				getValue: function(cluster: IncapsCluster0702) {
					const value = cluster[this.id]; // 32-bit integer
					if (typeof value !== "number") {
						return NaN;
					}
					const _multiplier = cluster[ClusterConstants.D0702.Attributes.Multiplier];
					const _divisor = cluster[ClusterConstants.D0702.Attributes.Divisor];
					const multiplier = (typeof _multiplier === "number") ? _multiplier : 1; // 32-bit integer
					const divisor = (typeof _divisor === "number") ? _divisor : 1; // 32-bit integer
					return this.decodeValue(value, multiplier, divisor);
				},
				getUnit: function() {
					return Constants.Units.Watt;
				},
				getDigits: function() {
					return 1;
				},
				decodeValue: function(value: number, multiplier: number, divisor: number) {
					return roundToPrecision(value * 1000 * multiplier / divisor);
				},
				formatValue: function(cluster: IncapsCluster0702, options: Options = {}) {
					return getUnitFormat(this, cluster, options);
				},
			},
			[ClusterConstants.D0702.Attributes.NetPowerConsumption]: {
				id: ClusterConstants.D0702.Attributes.NetPowerConsumption,
				cap: Constants.Caps.Incaps,
				getTitle: function() {
					return i18n.t("clusters.D0702.title");
				},
				getIcon: function() {
					return "smart-widget-state-icons/si_power.svg";
				},
				getValue: function(cluster: IncapsCluster0702) {
					const value = cluster[this.id]; // 32-bit integer
					if (typeof value !== "number") {
						return NaN;
					}
					const _multiplier = cluster[ClusterConstants.D0702.Attributes.Multiplier];
					const _divisor = cluster[ClusterConstants.D0702.Attributes.Divisor];
					const multiplier = (typeof _multiplier === "number") ? _multiplier : 1; // 32-bit integer
					const divisor = (typeof _divisor === "number") ? _divisor : 1; // 32-bit integer
					return this.decodeValue(value, multiplier, divisor);
				},
				getUnit: function() {
					return Constants.Units.Watt;
				},
				getDigits: function() {
					return 1;
				},
				decodeValue: function(value: number, multiplier: number, divisor: number) {
					return roundToPrecision(value * 1000 * multiplier / divisor);
				},
				formatValue: function(cluster: IncapsCluster0702, options: Options = {}) {
					return getUnitFormat(this, cluster, options);
				},
			},
		},
	},
	DC38A: {
		clusterId: "C38A",
		cap: Constants.Caps.Outcaps,
		attributeId: ClusterConstants.DC38A.Attributes.StreamStatus,
		cmp: DC38A,
		getDeviceIconsFromDevice: function(device: DeviceObj | EpDevice) {
			return device.getDeviceIcons(this.cap, this.clusterId, this.getIconStatusFromCluster);
		},
		getIconStatusFromCluster: function(cluster: OutcapsClusterC38A) {
			const value = cluster[ClusterConstants.DC38A.Attributes.StreamStatus];
			return value === "01";
		},
	},
	DC38B: {
		clusterId: "C38B",
		cap: Constants.Caps.Outcaps,
	},
	DC38C: {
		clusterId: "C38C",
		cap: Constants.Caps.Outcaps,
	},
	DC38E: {
		clusterId: "C38E",
		cap: Constants.Caps.Outcaps,
		cmp: DC38E,
		formatValue: function(cluster: OutcapsClusterC38E) {
			const attributeIds = Constants.NetatmoCameraNotificationIds.filter((attributeId) => (Boolean(cluster[attributeId])));
			return (attributeIds.length > 0)
				? attributeIds.map((attributeId) => (i18n.t(`clusters.DC38E.attributeIds.${attributeId}`))).join(" / ")
				: i18n.t("clusters.DC38E.idle");
		},
		getIconStatusFromCluster: function(cluster: OutcapsClusterC38E) {
			return Constants.NetatmoCameraNotificationIds.some((attributeId) => (Boolean(cluster[attributeId])));
		},
	},
	DF100: {
		clusterId: "F100",
		cap: Constants.Caps.Incaps,
		readOnly: true,
	},
	DF1F0: {
		clusterId: "F1F0",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DF1F0.Attributes.DeviceType,
		cmp: DF1F0,
	},
	DFC00: {
		clusterId: "FC00",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFC00.Attributes.OnOff,
		cmp: DFC00,
		getDeviceIconsFromDevice: function(device: DeviceObj | EpDevice) {
			return device.getDeviceIcons(this.cap, this.clusterId, this.getIconStatusFromCluster);
		},
		getIconStatusFromCluster: function(cluster: IncapsClusterFC00) {
			const value = cluster[ClusterConstants.DFC00.Attributes.OnOff];
			return value !== "00";
		},
	},
	DFD00: {
		clusterId: "FD00",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFD00.Attributes.PulsPerKwh,
		cmp: DFD00,
	},
	DFD01: {
		clusterId: "FD01",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFD01.Attributes.PH,
		cmp: DXXXX,
		getTitle: function() {
			return i18n.t("clusters.DFD01.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_ph.svg";
		},
		getUnit: function() {
			return "";
		},
		getDigits: function() {
			return 1;
		},
		getValue: function(cluster: IncapsClusterFD01) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: number) {
			return value / 100;
		},
		formatValue: function(cluster: IncapsClusterFD01, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	DFD02: {
		clusterId: "FD02",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFD02.Attributes.OydoReductionPotential,
		cmp: DXXXX,
		getTitle: function() {
			return i18n.t("clusters.DFD02.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_sanitizer.svg";
		},
		getUnit: function() {
			return Constants.Units.OydoReductionPotential;
		},
		getDigits: function() {
			return 0;
		},
		getValue: function(cluster: IncapsClusterFD02) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: number) {
			return value / 100;
		},
		formatValue: function(cluster: IncapsClusterFD02, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	DFD03: {
		clusterId: "FD03",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFD03.Attributes.SaltConcentration,
		cmp: DXXXX,
		getTitle: function() {
			return i18n.t("clusters.DFD03.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_tds.svg";
		},
		getUnit: function() {
			return Constants.Units.SaltCocentration;
		},
		getDigits: function() {
			return 1;
		},
		getValue: function(cluster: IncapsClusterFD03) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: number) {
			return value / 100;
		},
		formatValue: function(cluster: IncapsClusterFD03, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	DFD04: {
		clusterId: "FD04",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFD04.Attributes.TotalDissolvedSolids,
		cmp: DXXXX,
		getTitle: function() {
			return i18n.t("clusters.DFD04.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_tds.svg";
		},
		getUnit: function() {
			return Constants.Units.PartsPerMillion;
		},
		getDigits: function() {
			return 1;
		},
		getValue: function(cluster: IncapsClusterFD04) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: number) {
			return value / 100;
		},
		formatValue: function(cluster: IncapsClusterFD04, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	DFD05: {
		clusterId: "FD05",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFD05.Attributes.LatestWaitingRecommendation,
		cmp: DFD05,
		getTitle: function() {
			return i18n.t("clusters.DFD05.title");
		},
		getValue: function(cluster: IncapsClusterFD05) {
			const value = cluster[this.attributeId]; // 16-bit integer
			// if (typeof value !== "string") {
			// 	return undefined;
			// }
			return this.decodeValue(value);
		},
		decodeValue: function(value: string) {
			return value;
		},
		formatValue: function(cluster: IncapsClusterFD05) {
			return this.getValue(cluster);
		},
	},
	DFF69: {
		clusterId: "FF69",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFF69.Attributes.Temperature,
		cmp: DFF69,
		getTitle: function() {
			return i18n.t("clusters.DFF69.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_temperature.svg";
		},
		getValue: function(cluster: IncapsClusterFF69) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.Degree + Gateway.selectedGatewayTemperature;
		},
		getDigits: function() {
			return 1;
		},
		decodeValue: function(value: number) {
			return (Gateway.selectedGatewayTemperature === Constants.TempUnit.Fahrenheit)
				? convertToF(value / 100)
				: roundToPrecision(value / 100);
		},
		formatValue: function(cluster: IncapsClusterFF69, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	DFF71: {
		clusterId: "FF71",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFF71.Attributes.NotificationKey,
		cmp: DFF71,
		getValue: function(cluster: IncapsClusterFF71) {
			const value = cluster[this.attributeId]; // string
			if (typeof value !== "string") {
				return undefined;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: string) {
			return value;
		},
		formatValue: function(cluster: IncapsClusterFF71) {
			const value = this.getValue(cluster);
			const notificationString = cluster[ClusterConstants.DFF71.Attributes.NotificationString]; // string
			return i18n.exists(`zWaveNotifications.${value}`)
				? i18n.t(`zWaveNotifications.${value}`) as string // eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion
				: notificationString;
		},
	},
	DFF80: {
		clusterId: "FF80",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFF80.Attributes.CurrentSetpoint,
		cmp: DFF80,
		getValue: function(cluster: IncapsClusterFF80) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.Degree + Gateway.selectedGatewayTemperature;
		},
		getDigits: function() {
			return 1;
		},
		decodeValue: function(value: number) {
			return (Gateway.selectedGatewayTemperature === Constants.TempUnit.Fahrenheit)
				? convertToF(value / 100)
				: roundToPrecision(value / 100);
		},
		entries: {
			[ClusterConstants.DFF80.Attributes.CurrentMode]: {
				id: ClusterConstants.DFF80.Attributes.CurrentMode,
				getIcon: function() {
					return "smart-widget-state-icons/si_thermostat_mode.svg";
				},
				getValue: function(cluster: IncapsClusterFF80) {
					const value = cluster[this.id]; // 16-bit integer
					if (typeof value !== "number") {
						return NaN;
					}
					return this.decodeValue(value);
				},
				decodeValue: function(value: number) {
					return value;
				},
				formatValue: function(cluster: IncapsClusterFF80) {
					const value = this.getValue(cluster);
					const thermostatMode = Constants.ThermostatModes.find((thermostatMode) => (hex2Decimal(thermostatMode.value) === value));
					return thermostatMode ? i18n.t(`clusters.DFF80.modes.${thermostatMode.l10n}`) : "";
				},
			},
			[ClusterConstants.DFF80.Attributes.CurrentSetpoint]: {
				id: ClusterConstants.DFF80.Attributes.CurrentSetpoint,
				getValue: function(cluster: IncapsClusterFF80) {
					const value = cluster[this.id]; // 16-bit integer
					if (typeof value !== "number") {
						return NaN;
					}
					return this.decodeValue(value);
				},
				getUnit: function() {
					return Constants.Units.Degree + Gateway.selectedGatewayTemperature;
				},
				getDigits: function() {
					return 1;
				},
				decodeValue: function(value: number) {
					return (Gateway.selectedGatewayTemperature === Constants.TempUnit.Fahrenheit)
						? convertToF(value / 100)
						: roundToPrecision(value / 100);
				},
				formatValue: function(cluster: IncapsClusterFF80, options: Options = {}) {
					return getUnitFormat(this, cluster, options);
				},
			},
		},
	},
	DFF81: {
		clusterId: "FF81",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFF81.Attributes.CoLevel,
		cmp: DXXXX,
		getTitle: function() {
			return i18n.t("clusters.DFF81.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_co.svg";
		},
		getValue: function(cluster: IncapsClusterFF81) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.PartsPerMillion;
		},
		getDigits: function() {
			return 0;
		},
		decodeValue: function(value: number) {
			return Math.round(value / 100);
		},
		formatValue: function(cluster: IncapsClusterFF81, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	DFF82: {
		clusterId: "FF82",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFF82.Attributes.MaintenanceMode,
		cmp: DFF82,
		getValue: function(cluster: IncapsClusterFF82) {
			const value = cluster[this.attributeId]; // boolean
			if (typeof value !== "boolean") {
				return undefined;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: boolean) {
			return value;
		},
		formatValue: function(cluster: IncapsClusterFF82) {
			return this.getValue(cluster);
		},
	},
	DFF83: {
		clusterId: "FF83",
		cap: Constants.Caps.Incaps,
		cmp: DFF83,
	},
	DFF84: {
		clusterId: "FF84",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFF84.Attributes.DeviceStateROC,
		cmp: DFF84,
		getTitle: function() {
			return i18n.t("clusters.DFF84.title");
		},
		getIcon: function() {
			return "devices/di_lawn_mower.svg";
		},
		getValue: function(cluster: IncapsClusterFF84) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: number) {
			return value;
		},
		formatValue: function(cluster: IncapsClusterFF84) {
			const value = this.getValue(cluster);
			return i18n.exists(`clusters.DFF84.mowerState.${value}`)
				? i18n.t(`clusters.DFF84.mowerState.${value}`)
				: i18n.t("clusters.DFF84.mowerState.defaultMowerState");
		},
		getDeviceIconsFromDevice: function(device: DeviceObj | EpDevice) {
			return device.getDeviceIcons(this.cap, this.clusterId, this.getIconStatusFromCluster);
		},
		getIconStatusFromCluster: function(cluster: IncapsClusterFF84) {
			const value = cluster[ClusterConstants.DFF84.Attributes.DeviceStateROC];
			return [
				Constants.MowerStates.InLawn,
				Constants.MowerStates.ReturningToDock,
				Constants.MowerStates.LowPowerMode,
				Constants.MowerStates.LeavingDock,
			].includes(value); // [Mower_States] when device is inLawn
		},
	},
	DFF85: {
		clusterId: "FF85",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFF85.Attributes.LEDStatus,
		cmp: DFF85,
		getValue: function(cluster: IncapsClusterFF85) {
			const value = cluster[this.attributeId]; // 8-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: number) {
			return Boolean(value);
		},
		formatValue: function(cluster: IncapsClusterFF85) {
			return this.getValue(cluster);
		},
		entries: {
			[ClusterConstants.DFF85.Attributes.LEDStatus]: {
				id: ClusterConstants.DFF85.Attributes.LEDStatus,
				getValue: function(cluster: IncapsClusterFF85) {
					const value = cluster[this.id]; // 8-bit integer
					if (typeof value !== "number") {
						return NaN;
					}
					return this.decodeValue(value);
				},
				decodeValue: function(value: number) {
					return Boolean(value);
				},
				formatValue: function(cluster: IncapsClusterFF85) {
					return this.getValue(cluster);
				},
			},
			[ClusterConstants.DFF85.Attributes.LEDStatusWalliOutlet]: {
				id: ClusterConstants.DFF85.Attributes.LEDStatusWalliOutlet,
				getValue: function(cluster: IncapsClusterFF85) {
					const value = cluster[this.id]; // 8-bit integer
					if (typeof value !== "number") {
						return NaN;
					}
					return this.decodeValue(value);
				},
				decodeValue: function(value: number) {
					return value === hex2Decimal(ClusterConstants.DFF85.CmdPayloads.ColorByPower);
				},
				formatValue: function(cluster: IncapsClusterFF85) {
					return this.getValue(cluster);
				},
			},
		},
	},
	DFF92: {
		clusterId: "FF92",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFF92.Attributes.NukiDeviceType,
		cmp: DFF92,
		getValue: function(cluster: IncapsClusterFF92) {
			const value = cluster[this.attributeId]; // 8-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: number) {
			return value;
		},
		formatValue: function(cluster: IncapsClusterFF92) {
			const value = this.getValue(cluster);
			const deviceStateValue = cluster[ClusterConstants.DFF92.Attributes.NukiDeviceState] ?? 255;
			if (value === Constants.NukiDeviceTypes.DoorLock) {
				return i18n.t(`clusters.DFF92.doorLockStates.${deviceStateValue}`) as string; // eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion
			} else if (value === Constants.NukiDeviceTypes.Opener) {
				return i18n.t(`clusters.DFF92.openerStates.${deviceStateValue}`) as string; // eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion
			}
			return null;
		},
	},
	DFF94: {
		clusterId: "FF94",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFF94.Attributes.CO2Level,
		cmp: DXXXX,
		getTitle: function() {
			return i18n.t("clusters.DFF94.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_co2.svg";
		},
		getValue: function(cluster: IncapsClusterFF94) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.PartsPerMillion;
		},
		getDigits: function() {
			return 0;
		},
		decodeValue: function(value: number) {
			return Math.round(value / 100);
		},
		formatValue: function(cluster: IncapsClusterFF94, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	DFF95: {
		clusterId: "FF95",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFF95.Attributes.VOCLevel,
		cmp: DXXXX,
		getTitle: function() {
			return i18n.t("clusters.DFF95.title");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_voc.svg";
		},
		getValue: function(cluster: IncapsClusterFF95) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.PartsPerBillion;
		},
		getDigits: function() {
			return 0;
		},
		decodeValue: function(value: number) {
			return Math.round(value * 1000 / 100);
		},
		formatValue: function(cluster: IncapsClusterFF95, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	DFF96: {
		clusterId: "FF96",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFF96.Attributes.FoodNumber,
		cmp: DFF96,
		getValue: function(cluster: IncapsClusterFF96) {
			const value = cluster[this.attributeId]; // 16-bit integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		decodeValue: function(value: number) {
			return value;
		},
		formatValue: function(cluster: IncapsClusterFF96) {
			return this.getValue(cluster);
		},
	},
	DFF97: {
		clusterId: "FF97",
		cap: Constants.Caps.Incaps,
		cmp: DFF97,
	},
	/** @deprecated Use D0019 */
	DFF98: {
		clusterId: "FF98",
		cap: Constants.Caps.Incaps,
		readOnly: true,
	},
	DFF99: {
		clusterId: "FF99",
		cap: Constants.Caps.Incaps,
		cmp: DFF99,
	},
	DFF9A: {
		clusterId: "FF9A",
		cap: Constants.Caps.Incaps,
		cmp: DFF9A,
		entries: {
			[ClusterConstants.DFF9A.Attributes.LELPercentage]: {
				id: ClusterConstants.DFF9A.Attributes.LELPercentage,
				getTitle: function() {
					return i18n.t("clusters.DFF9A.lel");
				},
				getValue: function(cluster: IncapsClusterFF9A) {
					const value = cluster[this.id]; // integer
					if (typeof value !== "number") {
						return NaN;
					}
					return this.decodeValue(value);
				},
				getUnit: function() {
					return Constants.Units.Percent;
				},
				getDigits: function() {
					return 0;
				},
				decodeValue: function(value: number) {
					return value / 100;
				},
				formatValue: function(cluster: IncapsClusterFF9A, options: Options = {}) {
					return getUnitFormat(this, cluster, options);
				},
			},
		},
	},
	DFF9B: {
		clusterId: "FF9B",
		cap: Constants.Caps.Incaps,
		cmp: DFF9B,
	},
	DFF9C: {
		clusterId: "FF9C",
		cap: Constants.Caps.Incaps,
		cmp: DFF9C,
	},
	DFF9D: {
		clusterId: "FF9D",
		cap: Constants.Caps.Incaps,
		cmp: DFF9D,
	},
	DFF9E: {
		clusterId: "FF9E",
		cap: Constants.Caps.Incaps,
		cmp: DFF9E,
		entries: {
			[ClusterConstants.DFF9E.Attributes.SDCardStatus]: {
				id: ClusterConstants.DFF9E.Attributes.SDCardStatus,
				getTitle: function() {
					return i18n.t("clusters.DFF9E.sdCard");
				},
				getValue: function(cluster: IncapsClusterFF9E) {
					const value = cluster[this.id]; // integer
					if (typeof value !== "number") {
						return NaN;
					}
					return this.decodeValue(value);
				},
				decodeValue: function(value: number) {
					return value;
				},
				formatValue: function(cluster: IncapsClusterFF9E) {
					const value = this.getValue(cluster);
					return i18n.exists(`clusters.DFF9E.sdcardStates.${value}`)
						? i18n.t(`clusters.DFF9E.sdcardStates.${value}`) as string // eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion
						: i18n.t("clusters.DFF9E.sdcardStates.255");
				},
			},
			[ClusterConstants.DFF9E.Attributes.PowerAdapterStatus]: {
				id: ClusterConstants.DFF9E.Attributes.PowerAdapterStatus,
				getTitle: function() {
					return i18n.t("clusters.DFF9E.powerAdapter");
				},
				getValue: function(cluster: IncapsClusterFF9E) {
					const value = cluster[this.id]; // integer
					if (typeof value !== "number") {
						return NaN;
					}
					return this.decodeValue(value);
				},
				decodeValue: function(value: number) {
					return value;
				},
				formatValue: function(cluster: IncapsClusterFF9E) {
					const value = this.getValue(cluster);
					return i18n.exists(`clusters.DFF9E.powerAdapterStates.${value}`)
						? i18n.t(`clusters.DFF9E.powerAdapterStates.${value}`) as string // eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion
						: i18n.t("clusters.DFF9E.powerAdapterStates.255");
				},
			},
		},
	},
	DFF9F: {
		clusterId: "FF9F",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFF9F.Attributes.PM25ParticleValue,
		cmp: DXXXX,
		getTitle: function() {
			return i18n.t("clusters.DFF9F.pm25");
		},
		getIcon: function() {
			return "smart-widget-state-icons/si_pm2_5.svg";
		},
		getValue: function(cluster: IncapsClusterFF9F) {
			const value = cluster[this.attributeId]; // integer
			if (typeof value !== "number") {
				return NaN;
			}
			return this.decodeValue(value);
		},
		getUnit: function() {
			return Constants.Units.MicroGramPerCubicMeter;
		},
		getDigits: function() {
			return 0;
		},
		decodeValue: function(value: number) {
			return value / 100;
		},
		formatValue: function(cluster: IncapsClusterFF9F, options: Options = {}) {
			return getUnitFormat(this, cluster, options);
		},
	},
	DFFAC: {
		clusterId: "FFAC",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFFAC.Attributes.OperationState,
		cmp: DFFAC,
		getDeviceIconsFromDevice: function(device: DeviceObj | EpDevice) {
			return device.getDeviceIcons(this.cap, this.clusterId, this.getIconStatusFromCluster);
		},
		getIconStatusFromCluster: function(cluster: IncapsClusterFFAC) {
			const operationState = cluster[ClusterConstants.DFFAC.Attributes.OperationState];
			return !START_SWITCH_OPERATION_STATE_ENABLED.includes(operationState) && Boolean(cluster[ClusterConstants.DFFAC.Attributes.CurrentPowerState]);
		},
		entries: {
			[ClusterConstants.DFFAC.Attributes.OperationState]: {
				getIconStatusFromCluster: function(cluster: IncapsClusterFFAC) {
					const operationState = cluster[ClusterConstants.DFFAC.Attributes.OperationState];
					return !START_SWITCH_OPERATION_STATE_ENABLED.includes(operationState);
				},
			},
			[ClusterConstants.DFFAC.Attributes.CurrentPowerState]: {
				getIconStatusFromCluster: function(cluster: IncapsClusterFFAC) {
					return Boolean(cluster[ClusterConstants.DFFAC.Attributes.CurrentPowerState]);
				},
			},
		},
	},
	DFFAD: {
		clusterId: "FFAD",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFFAD.Attributes.PhoneMode,
		cmp: DFFAD,
		getTitle: function() {
			return i18n.t("clusters.DFFAD.phoneStatusTitle");
		},
	},
	DFFAE: {
		clusterId: "FFAE",
		cap: Constants.Caps.Incaps,
		attributeId: ClusterConstants.DFFAE.Attributes.NotificationType,
		cmp: DFFAE,
		getTitle: function() {
			return i18n.t("clusters.DFFAE.notification");
		},
	},
} as const;

export default DeviceType;
